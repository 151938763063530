import {createRouter, createWebHashHistory} from 'vue-router'
import Home from '../views/Home.vue'
import PrivacyPolicy from "@/views/PrivacyPolicy";
import Conditions from "@/views/Conditions";
import Imprint from "@/views/Imprint";


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/g/:token',
    name: 'Generator',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Generator.vue')
  },
  {
    path: '/already_completed',
    name: 'AlreadyCompleted',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AlreadyCompleted.vue')
  },
  {
    path: '/completed_success',
    name: 'CompletedSuccess',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CompletedSuccess.vue')
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Imprint
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: PrivacyPolicy
  },
  {
    path: '/agb',
    name: 'AGB',
    component: Conditions
  },
  {
    path: '/:catchAll(.*)',
    name: 'Home',
    component: Home
  },
]

const router = new createRouter({
  history: createWebHashHistory(),
  routes: routes
})

export default router

<template>
  <div id="app">
    <div class="navigation">
      <div class="image-col"></div>
      <div class="text-col">selbst gestalten</div>
      <div class="text-col">schnell geliefert</div>
      <div class="text-col">kostenloser Support</div>
      <div class="support-col">
        <span class="text">Hilfe bei der Gestaltung?</span>
        <br/>
        <a class="mail" href="mailto:backen@pimpmycake24.de"
        >backen@pimpmycake24.de</a
        >
      </div>
    </div>

    <router-view/>

    <div class="footer">
      <router-link to="/impressum" target="_blank" class="text-col">Impressum</router-link>
      <router-link to="/datenschutz" target="_blank" class="text-col">Datenschutz</router-link>
      <router-link to="/agb" target="_blank" class="text-col">AGB</router-link>
    </div>
  </div>
</template>

<style lang="scss">
@import "./assets/variables";
@import "vue-select/dist/vue-select.css";

body {
  font-family: Verdana, serif;
}

.container {
  min-height: 80vh;
}

.navigation {
  display: flex;
  justify-content: space-between;
  height: 55px;
  background-color: $very-light-grey;
  font-family: Verdana;

  .image-col {
    background-image: url(./assets/logo.png);
    width: 98px;
    height: 85px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 48px;
    margin-top: 10px;
  }

  .text-col {
    line-height: 55px;
    color: $grey;

    &::before {
      content: "";
      position: absolute;
      background-image: url("./assets/icons/success.png");
      width: 14px;
      height: 10px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      top: 23px;
      margin-left: -20px;
    }
  }

  .support-col {
    color: $grey;
    margin-right: 48px;
    margin-top: 7px;

    .mail {
      color: $grey;
      text-decoration: underline;
    }

    &::before {
      content: "";
      position: absolute;
      background-image: url("./assets/icons/question-mark.png");
      width: 20px;
      height: 20px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      top: 17px;
      margin-left: -30px;
    }
  }
}

.footer {
  display: flex;
  height: 55px;
  background-color: $very-light-grey;
  font-family: Verdana;
  justify-content: flex-start;
  padding: 0 48px;

  .text-col {
    line-height: 55px;
    color: $grey;
    padding: 0 24px;
  }
}
</style>

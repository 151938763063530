<template>
  <div class="container">
    <h2>Hoppla, die von Dir aufgerufene Seite konnten wir nicht finden.</h2>
    <h5>Fehler 404 - Seite nicht gefunden</h5>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {}
};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
@import "./../assets/variables";

.container {
  margin-top: 100px;
  text-align: center;
  color: $grey;

  h2 {
    font-weight: bold;
    font-size: 24px;
  }

  h5 {
    font-size: 18px;
    margin-top: 24px;
    font-weight: normal;

    a {
      color: $grey;
    }
  }
}
</style>

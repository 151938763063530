<template>
  <div class="container" style="text-align: left;">
    <p><strong>Datenschutzerkl&auml;rung</strong></p>
    <p><strong>Verantwortlicher
      f&uuml;r die Datenverarbeitung ist:</strong><br/> Surhoff und Lange GbR<br/>
      Frankfurter Str. 33/1<br/> 74072 Heilbronn<br/> Deutschland<br/>
      info@pimpmycake24.de</p>
    <p>Wir freuen uns über Ihr Interesse an unserem
      Online-Shop. Der Schutz Ihrer Privatsphäre ist für uns sehr wichtig. Nachstehend
      informieren wir Sie ausführlich über den Umgang mit Ihren Daten.<br></p>
    <h2>1.&nbsp;Zugriffsdaten und Hosting</h2>
    <p>Sie können unsere Webseiten
      besuchen, ohne Angaben zu Ihrer Person zu machen. Bei jedem Aufruf einer
      Webseite speichert der Webserver lediglich automatisch ein sogenanntes Server-
      Logfile, das z.B. den Namen der angeforderten Datei, Ihre IP-Adresse, Datum und
      Uhrzeit des Abrufs, übertragene Datenmenge und den anfragenden Provider
      (Zugriffsdaten) enthält und den Abruf dokumentiert. Diese Zugriffsdaten werden
      ausschließlich zum Zwecke der Sicherstellung eines störungsfreien Betriebs der
      Seite sowie der Verbesserung unseres Angebots ausgewertet. Dies dient der
      Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden
      berechtigten Interessen an einer korrekten Darstellung unseres Angebots gemäß
      Art. 6 Abs. 1 S. 1 lit. f DSGVO. Alle Zugriffsdaten werden spätestens sieben Tage
      nach Ende Ihres Seitenbesuchs gelöscht.</p>
    <h2>&nbsp;Hosting</h2>
    <p>Die
      Dienste zum Hosting und zur Darstellung der Webseite werden teilweise durch
      unsere Dienstleister im Rahmen einer Verarbeitung in unserem Auftrag erbracht.
      Soweit im Rahmen der vorliegenden Datenschutzerklärung nichts anderes erläutert
      wird, werden alle Zugriffsdaten sowie alle Daten, die in dafür vorgesehenen
      Formularen auf dieser Webseite erhoben werden, auf ihren Servern verarbeitet. Bei
      Fragen zu unseren Dienstleistern und der Grundlage unserer Zusammenarbeit mit
      ihnen wenden Sie sich bitte an die in dieser Datenschutzerklärung beschriebenen
      Kontaktmöglichkeit.</p>
    <h2>2.&nbsp;Datenverarbeitung zur Vertragsabwicklung
      und zur Kontaktaufnahme</h2>
    <p>Wir erheben personenbezogene Daten, wenn
      Sie uns diese im Rahmen Ihrer Bestellung oder bei einer Kontaktaufnahme mit uns
      (z.B. per Kontaktformular oder E-Mail) freiwillig mitteilen. Pflichtfelder werden als
      solche gekennzeichnet, da wir in diesen Fällen die Daten zwingend zur
      Vertragsabwicklung, bzw. zur Bearbeitung Ihrer Kontaktaufnahme benötigen und Sie
      ohne deren Angabe die Bestellung nicht abschließen, bzw. die Kontaktaufnahme
      nicht versenden können. Welche Daten erhoben werden, ist aus den jeweiligen
      Eingabeformularen ersichtlich.<br>Wir verwenden die von Ihnen mitgeteilten Daten
      zur Vertragsabwicklung und Bearbeitung Ihrer Anfragen gemäß Art. 6 Abs. 1 S. 1 lit.
      b DSGVO. Weitere Informationen zu der Verarbeitung Ihrer Daten, insbesondere zu
      der Weitergabe an unsere Dienstleister zum Zwecke der Bestellungs-, Zahlungs-
      und Versandabwicklung, finden Sie in den nachfolgenden Abschnitten dieser
      Datenschutzerklärung. Nach vollständiger Abwicklung des Vertrages werden Ihre
      Daten für die weitere Verarbeitung eingeschränkt und nach Ablauf etwaiger steuer-
      und handelsrechtlichen Aufbewahrungsfristen gemäß Art. 6 Abs. 1 S. 1 lit. c DSGVO
      gelöscht, sofern Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten gemäß
      Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben oder wir uns eine darüber
      hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über
      die wir Sie in dieser Erklärung informieren.</p>
    <h2>3.&nbsp;Datenverarbeitung
      zum Zwecke der Versandabwicklung</h2>
    <p>Zur Vertragserfüllung gemäß Art. 6
      Abs. 1 S. 1 lit. b DSGVO geben wir Ihre Daten an den mit der Lieferung beauftragten
      Versanddienstleister weiter, soweit dies zur Lieferung bestellter Waren erforderlich
      ist.<br></p>
    <h2>4.&nbsp;Datenverarbeitung zur Zahlungsabwicklung</h2>
    <p>Bei
      der Abwicklung von Zahlungen in unserem Online-Shop arbeiten wir mit diesen
      Partnern zusammen: technische Dienstleister, Kreditinstitute,
      Zahlungsdienstleister.</p>
    <h2>4.1&nbsp;Datenverarbeitung zur
      Transaktionsabwicklung</h2>
    <p>Je nach ausgew&auml;hlter Zahlungsart geben
      wir die f&uuml;r die Abwicklung der Zahlungstransaktion notwendigen Daten an
      unsere technischen Dienstleister, die im Rahmen einer Auftragsverarbeitung
      f&uuml;r uns t&auml;tig sind, oder an die beauftragten Kreditinstitute oder an den
      ausgew&auml;hlten Zahlungsdienstleister weiter, soweit dies zur Abwicklung der
      Zahlung erforderlich ist. Dies dient der Vertragserf&uuml;llung gem&auml;&szlig; Art.
      6 Abs. 1 S. 1 lit. b DSGVO. Zum Teil erheben die Zahlungsdienstleister die f&uuml;r
      die Abwicklung der Zahlung erforderlichen Daten selbst, z. B. auf ihrer eigenen
      Webseite oder &uuml;ber eine technische Einbindung im Bestellprozess. Es gilt
      insoweit die Datenschutzerkl&auml;rung des jeweiligen Zahlungsdienstleisters.<br/>
      Bei Fragen zu unseren Partnern f&uuml;r die Zahlungsabwicklung und der
      Grundlage unserer Zusammenarbeit mit ihnen wenden Sie sich bitte an die in dieser
      Datenschutzerkl&auml;rung beschriebenen Kontaktm&ouml;glichkeit.</p>
    <h2>4.2&nbsp;Datenverarbeitung zum Zwecke der Betrugspr&auml;vention und der
      Optimierung unserer Zahlungsprozesse</h2>
    <p>Gegebenenfalls geben wir
      unseren Dienstleistern weitere Daten, die sie zusammen mit den für die Abwicklung
      der Zahlung notwendigen Daten als unsere Auftragsverarbeiter zum Zwecke der
      Betrugsprävention und der Optimierung unserer Zahlungsprozesse (z.B.
      Rechnungsstellung, Abwicklung von angefochtenen Zahlungen, Unterstützung der
      Buchhaltung) verwenden. Dies dient gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO der
      Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden
      berechtigten Interessen an unserer Absicherung gegen Betrug bzw. an einem
      effizienten Zahlungsmanagement.</p>
    <h2>5.&nbsp;Werbung per E-Mail</h2>
    <h2>5.1&nbsp;E-Mail-Newsletter mit Anmeldung</h2>
    <p>Wenn Sie sich zu
      unserem Newsletter anmelden, verwenden wir die hierf&uuml;r erforderlichen oder
      gesondert von Ihnen mitgeteilten Daten, um Ihnen regelm&auml;&szlig;ig unseren E-
      Mail-Newsletter aufgrund Ihrer Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 S. 1 lit. a
      DSGVO zuzusenden. Die Abmeldung vom Newsletter ist jederzeit möglich und kann
      entweder durch eine Nachricht an die unten beschriebene Kontaktmöglichkeit oder
      über einen dafür vorgesehenen Link im Newsletter erfolgen. Nach Abmeldung
      l&ouml;schen wir Ihre E-Mail-Adresse aus der Empf&auml;ngerliste, soweit Sie nicht
      ausdr&uuml;cklich in eine weitere Nutzung Ihrer Daten gem&auml;&szlig; Art. 6 Abs.
      1 S. 1 lit. a DSGVO eingewilligt haben oder wir uns eine dar&uuml;ber
      hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und
      &uuml;ber die wir Sie in dieser Erkl&auml;rung informieren.
    </p>
    <h2>5.2&nbsp;Newsletterversand</h2>
    <p>Der Newsletter wird gegebenenfalls
      auch durch unsere Dienstleister im Rahmen einer Verarbeitung in unserem Auftrag
      versendet. Bei Fragen zu unseren Dienstleistern und der Grundlage unserer
      Zusammenarbeit mit ihnen wenden Sie sich bitte an die in dieser
      Datenschutzerklärung beschriebenen Kontaktmöglichkeit.</p>
    <p>Soweit Sie in die
      Verwendung der Technologien gem&auml;&szlig; Art. 6 Abs. 1 S. 1 lit. a DSGVO
      eingewilligt haben, k&ouml;nnen Sie Ihre Einwilligung jederzeit widerrufen durch eine
      Nachricht an die in der Datenschutzerkl&auml;rung beschriebenen
      Kontaktm&ouml;glichkeit. </p>
    <h2>6.&nbsp;Social Media</h2>
    <h2>6.1&nbsp;Social Plugins von Facebook, Instagram, Pinterest</h2>
    <p>Auf
      unserer Webseite werden Social Buttons von sozialen Netzwerken verwendet. Diese
      sind lediglich als HTML-Links in die Seite eingebunden, so dass beim Aufruf unserer
      Webseite noch keine Verbindung mit den Servern des jeweiligen Anbieters
      hergestellt wird. Klicken Sie auf einen der Buttons, &ouml;ffnet sich die Webseite
      des jeweiligen sozialen Netzwerks in einem neuen Fenster Ihres Browsers Dort
      k&ouml;nnen Sie z.B. den Like- oder Share-Button bet&auml;tigen.</p>
    <h2>6.2&nbsp;Unsere Onlinepr&auml;senz auf Facebook, Instagram,
      Pinterest</h2>
    <p>Soweit Sie hierzu Ihre Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a
      DSGVO gegenüber dem jeweiligen Social Media Betreiber erteilt haben, werden bei
      Besuch unserer Onlinepräsenzen auf den in der oben genannten sozialen Medien
      Ihre Daten für Marktforschungs- und Werbezwecke automatisch erhoben und
      gespeichert, aus denen unter Verwendung von Pseudonymen Nutzungsprofile
      erstellt werden. Diese können verwendet werden, um z.B. Werbeanzeigen innerhalb
      und außerhalb der Plattformen zu schalten, die mutmaßlich Ihren Interessen
      entsprechen. Hierzu werden im Regelfall Cookies eingesetzt. Die detaillierten
      Informationen zur Verarbeitung und Nutzung der Daten durch den jeweiligen Social
      Media Betreiber sowie eine Kontaktmöglichkeit und Ihre diesbezüglichen Rechte und
      Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre, entnehmen Sie bitte den
      unten verlinkten Datenschutzhinweisen der Anbieter. Sollten Sie diesbezüglich
      dennoch Hilfe benötigen, können Sie sich an uns wenden.</p>
    <p><a data-cke-
          saved-href="https://www.facebook.com/about/privacy/"
          href="https://www.facebook.com/about/privacy/"
          target="_blank"><strong>Facebook
    </strong></a>ist ein Angebot der Facebook Ireland Ltd., 4 Grand Canal Square,
      Dublin 2, Irland („Facebook Ireland“) Die durch Facebook Ireland automatisch
      erhobenen Informationen über Ihre Nutzung unserer Online-Präsenz auf Facebook
      werden in der Regel an einen Server der Facebook, Inc., 1601 Willow Road, Menlo
      Park, California 94025, USA übertragen und dort gespeichert. F&uuml;r die USA
      liegt kein Angemessenheitsbeschluss der Europ&auml;ischen Kommission vor.
      Unsere Kooperation st&uuml;tzt sich auf Standarddatenschutzklauseln der
      Europ&auml;ischen Kommission. Die Datenverarbeitung im Rahmen des Besuchs
      einer Facebook Fanpage erfolgt auf Grundlage einer Vereinbarung zwischen
      gemeinsam Verantwortlichen gemäß Art. 26 DSGVO. Weitere Informationen
      (Informationen zu Insights-Daten) finden Sie <a data-cke-saved-
                                                      href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
                                                      target="_blank">hier</a>.</p>
    <p><a data-cke-saved-
          href="https://help.instagram.com/519522125107875"
          target="_blank"><strong>Instagram</strong></a> ist ein Angebot der Facebook
      Ireland Ltd., 4 Grand Canal Square, Dublin 2, Irland („Facebook Ireland“) Die durch
      Facebook Ireland automatisch erhobenen Informationen über Ihre Nutzung unserer
      Online-Präsenz auf Instagram werden in der Regel an einen Server der Facebook,
      Inc., 1601 Willow Road, Menlo Park, California 94025, USA übertragen und dort
      gespeichert. F&uuml;r die USA liegt kein Angemessenheitsbeschluss der
      Europ&auml;ischen Kommission vor. Unsere Kooperation st&uuml;tzt sich auf
      Standarddatenschutzklauseln der Europ&auml;ischen Kommission. Die
      Datenverarbeitung im Rahmen des Besuchs einer Instagram Fanpage erfolgt auf
      Grundlage einer Vereinbarung zwischen gemeinsam Verantwortlichen gemäß Art.
      26 DSGVO. Weitere Informationen (Informationen zu Insights-Daten) finden Sie <a
          data-cke-saved-
          href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
          target="_blank">hier</a>.</p>
    <p><a data-cke-saved-
          href="https://about.pinterest.com/de/privacy-policy"
          target="_blank"><strong>Pinterest </strong></a>ist ein Angebot der Pinterest
      Europe Ltd., Palmerston House, 2nd Floor, Fenian Street, Dublin 2, Irland
      („Pinterest“). Die von Pinterest automatisch erhobenen Informationen über Ihre
      Nutzung unserer Online-Präsenz auf Pinterest werden in der Regel an einen Server
      der Pinterest, Inc., 505 Brannan St., San Francisco, CA 94107, USA übertragen und
      dort gespeichert. F&uuml;r die USA liegt kein Angemessenheitsbeschluss der
      Europ&auml;ischen Kommission vor. Unsere Kooperation st&uuml;tzt sich auf
      Standarddatenschutzklauseln der Europ&auml;ischen Kommission.</p>
    <h2>7.&nbsp;Kontaktmöglichkeiten und Ihre Rechte </h2>
    <p>Als Betroffener
      haben Sie folgende Rechte:</p>
    <ul>
      <li>gem&auml;&szlig; Art. 15 DSGVO das
        Recht, in dem dort bezeichneten Umfang Auskunft &uuml;ber Ihre von uns
        verarbeiteten personenbezogenen Daten zu verlangen;
      </li>
      <li>gem&auml;&szlig;
        Art. 16 DSGVO das Recht, unverz&uuml;glich die Berichtigung unrichtiger oder
        Vervollst&auml;ndigung Ihrer bei uns gespeicherten personenbezogenen Daten zu
        verlangen;
      </li>
      <li>gem&auml;&szlig; Art. 17 DSGVO das Recht, die L&ouml;schung
        Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht
        die weitere Verarbeitung
        <ul>
          <li>zur Aus&uuml;bung des Rechts auf freie
            Meinungs&auml;u&szlig;erung und Information;
          </li>
          <li>zur Erf&uuml;llung einer
            rechtlichen Verpflichtung;
          </li>
          <li>aus Gr&uuml;nden des &ouml;ffentlichen
            Interesses oder
          </li>
          <li>zur Geltendmachung, Aus&uuml;bung oder Verteidigung
            von Rechtsanspr&uuml;chen erforderlich ist;
          </li>
        </ul>
      </li>
      <li>gem&auml;&szlig;
        Art. 18 DSGVO das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen, soweit
        <ul>
          <li>die Richtigkeit der Daten
            von Ihnen bestritten wird;
          </li>
          <li>die Verarbeitung unrechtm&auml;&szlig;ig ist, Sie
            aber deren L&ouml;schung ablehnen;
          </li>
          <li>wir die Daten nicht mehr
            ben&ouml;tigen, Sie diese jedoch zur Geltendmachung, Aus&uuml;bung oder
            Verteidigung von Rechtsanspr&uuml;chen ben&ouml;tigen oder
          </li>
          <li>Sie
            gem&auml;&szlig; Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt
            haben;
          </li>
        </ul>
      </li>
      <li>gem&auml;&szlig; Art. 20 DSGVO das Recht, Ihre
        personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten,
        g&auml;ngigen und maschinenlesebaren Format zu erhalten oder die
        &Uuml;bermittlung an einen anderen Verantwortlichen zu verlangen;
      </li>
      <li>gem&auml;&szlig; Art. 77 DSGVO das Recht, sich bei einer
        Aufsichtsbeh&ouml;rde zu beschweren. In der Regel k&ouml;nnen Sie sich
        hierf&uuml;r an die Aufsichtsbeh&ouml;rde Ihres &uuml;blichen Aufenthaltsortes
        oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden.
      </li>
    </ul>
    Bei
    Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten,
    bei Auskünften, Berichtigung, Einschränkung oder Löschung von Daten sowie
    Widerruf erteilter Einwilligungen oder Widerspruch gegen eine bestimmte
    Datenverwendung wenden Sie sich bitte direkt an uns über die Kontaktdaten in
    unserem Impressum.
    <table border="1" cellpadding="1" cellspacing="1"
           style="width:100%">
      <tbody>
      <tr>
        <td>
          <p><strong>Widerspruchsrecht</strong><br/> Soweit wir zur Wahrung unserer im
            Rahmen einer Interessensabw&auml;gung &uuml;berwiegenden berechtigten
            Interessen personenbezogene Daten wie oben erl&auml;utert verarbeiten,
            k&ouml;nnen Sie dieser Verarbeitung mit Wirkung f&uuml;r die Zukunft
            widersprechen. Erfolgt die Verarbeitung zu Zwecken des Direktmarketings,
            k&ouml;nnen Sie dieses Recht jederzeit wie oben beschrieben aus&uuml;ben.
            Soweit die Verarbeitung zu anderen Zwecken erfolgt, steht Ihnen ein
            Widerspruchsrecht nur bei Vorliegen von Gr&uuml;nden, die sich aus Ihrer
            besonderen Situation ergeben, zu.</p>
          <p>Nach Aus&uuml;bung Ihres
            Widerspruchsrechts werden wir Ihre personenbezogenen Daten nicht weiter zu
            diesen Zwecken verarbeiten, es sei denn, wir k&ouml;nnen zwingende
            schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die Ihre
            Interessen, Rechte und Freiheiten &uuml;berwiegen, oder wenn die Verarbeitung
            der Geltendmachung, Aus&uuml;bung oder Verteidigung von
            Rechtsanspr&uuml;chen dient.</p>
          <p>Dies gilt nicht, wenn die Verarbeitung zu
            Zwecken des Direktmarketings erfolgt. Dann werden wir Ihre personenbezogenen
            Daten nicht weiter zu diesem Zweck verarbeiten.</p></td>
      </tr>
      </tbody>
    </table>
    <br>
    <p><i><a target="_blank" title="Datenschutzerklärung"
             href="https://shop.trustedshops.com/de/rechtstexte/">Datenschutzerklärung</a>
      erstellt mit dem <a target="_blank" title="Trusted Shops"
                          href="https://shop.trustedshops.com/de/">Trusted
        Shops</a> Rechtstexter in
      Kooperation mit <a target="_blank" title="FÖHLISCH Rechtsanwälte"
                         href="https://foehlisch.com"
                         rel="nofollow">FÖHLISCH Rechtsanwälte</a>.</i></p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
@import "./../assets/variables";

.container {
  margin-top: 100px;
  color: $grey;
  text-align: left;

  h2 {
    font-weight: bold;
    font-size: 24px;
  }

  h5 {
    font-size: 18px;
    margin-top: 24px;
    font-weight: normal;

    a {
      color: $grey;
    }
  }
}
</style>

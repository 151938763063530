<template>
  <div class="container">
    <p><strong><span style="font-size:16px">Impressum</span></strong></p>
    <p><strong>Surhoff und Lange GbR, pimpmycake24</strong></p>
    <p>vertretungsberechtigte Gesellschafter: Milan Lange, Florian Surhoff </p>
    <p>Hauptstr. 11a<br/> 65760 Eschborn<br/> Deutschland<br/> <br/></p>
    <p>Telefon: 061965820581</p>
    <p>Mail: info@pimpmycake24.de</p>
    <p>Umsatzsteuer-Identifikationsnummer: DE317664535</p>
    <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden
      <a href="https://ec.europa.eu/consumers/odr/" target="_blank">https://ec.europa.eu/consumers/odr/</a>. Wir sind
      bereit, an einem au&szlig;ergerichtlichen Schlichtungsverfahren vor einer Verbraucherschlichtungsstelle
      teilzunehmen. Zust&auml;ndig ist die Universalschlichtungsstelle des Bundes am Zentrum f&uuml;r Schlichtung e.V.,
      Stra&szlig;burger Stra&szlig;e 8, 77694 Kehl am Rhein, <a href="http://www.universalschlichtungsstelle.de"
                                                                rel="noopener noreferrer" target="_blank">www.universalschlichtungsstelle.de</a>.
    </p>
      <br>
    <p><i><a target="_blank" title="Impressum" href="https://shop.trustedshops.com/de/rechtstexte/">Impressum</a>
      erstellt mit dem <a target="_blank" title="Trusted Shops" href="https://shop.trustedshops.com/de/">Trusted
        Shops</a> Rechtstexter in Kooperation mit <a target="_blank" title="FÖHLISCH Rechtsanwälte"
                                                     href="https://foehlisch.com" rel="nofollow">FÖHLISCH
        Rechtsanwälte</a>.</i></p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
@import "./../assets/variables";

.container {
  margin-top: 100px;
  text-align: center;
  color: $grey;

  h2 {
    font-weight: bold;
    font-size: 24px;
  }

  h5 {
    font-size: 18px;
    margin-top: 24px;
    font-weight: normal;

    a {
      color: $grey;
    }
  }
}
</style>

<template>
  <div class="container" style="text-align: left;">
    <p><strong>Allgemeine Gesch&auml;ftsbedingungen</strong></p>
    <h2>1.&nbsp;Geltungsbereich</h2>
    <p>F&uuml;r alle Bestellungen &uuml;ber unseren Online-Shop gelten die nachfolgenden AGB. Unser Online-Shop richtet
      sich ausschlie&szlig;lich an Verbraucher.</p>
    <p>Verbraucher ist jede nat&uuml;rliche Person, die ein Rechtsgesch&auml;ft zu Zwecken abschlie&szlig;t, die &uuml;berwiegend
      weder ihrer gewerblichen noch ihrer selbst&auml;ndigen beruflichen T&auml;tigkeit zugerechnet werden k&ouml;nnen.
      Unternehmer ist eine nat&uuml;rliche oder juristische Person oder eine rechtsf&auml;hige Personengesellschaft, die
      bei Abschluss eines Rechtsgesch&auml;fts in Aus&uuml;bung ihrer gewerblichen oder selbst&auml;ndigen beruflichen T&auml;tigkeit
      handelt.</p>
    <p>Gegen&uuml;ber Unternehmern gelten diese AGB auch f&uuml;r k&uuml;nftige Gesch&auml;ftsbeziehungen, ohne dass wir
      nochmals auf sie hinweisen m&uuml;ssten. Verwendet der Unternehmer entgegenstehende oder erg&auml;nzende
      Allgemeine Gesch&auml;ftsbedingungen, wird deren Geltung hiermit widersprochen; sie werden nur dann
      Vertragsbestandteil, wenn wir dem ausdr&uuml;cklich zugestimmt haben.</p>
    <h2>2.&nbsp;Vertragspartner, Vertragsschluss, Korrekturm&ouml;glichkeiten</h2>
    <p>Der Kaufvertrag kommt zustande mit Surhoff und Lange GbR.</p>
    <p>Mit Einstellung der Produkte in den Online-Shop geben wir ein verbindliches Angebot zum Vertragsschluss &uuml;ber
      diese Artikel ab. Sie k&ouml;nnen unsere Produkte zun&auml;chst unverbindlich in den Warenkorb legen und Ihre
      Eingaben vor Absenden Ihrer verbindlichen Bestellung jederzeit korrigieren, indem Sie die hierf&uuml;r im
      Bestellablauf vorgesehenen und erl&auml;uterten Korrekturhilfen nutzen. Der Vertrag kommt zustande, indem Sie
      durch Anklicken des Bestellbuttons das Angebot &uuml;ber die im Warenkorb enthaltenen Waren annehmen. Unmittelbar
      nach dem Absenden der Bestellung erhalten Sie noch einmal eine Best&auml;tigung per E-Mail.</p>
    <h2>3.&nbsp;Vertragssprache, Vertragstextspeicherung</h2>
    <p>Die f&uuml;r den Vertragsschluss zur Verf&uuml;gung stehende(n) Sprache(n): Deutsch</p>
    <p>Wir speichern den Vertragstext und senden Ihnen die Bestelldaten und unsere AGB in Textform zu. Der Vertragstext
      ist aus Sicherheitsgr&uuml;nden nicht mehr &uuml;ber das Internet zug&auml;nglich.</p>
    <h2>4.&nbsp;Lieferbedingungen </h2>
    <p>Zuz&uuml;glich zu den angegebenen Produktpreisen k&ouml;nnen noch Versandkosten anfallen. N&auml;here
      Bestimmungen zu ggf. anfallenden Versandkosten erfahren Sie bei den Angeboten.</p>
    <p>Wir liefern nur im Versandweg. Eine Selbstabholung der Ware ist leider nicht m&ouml;glich.</p>
    <h2>5.&nbsp;Bezahlung </h2>
    <p>In unserem Shop stehen Ihnen grunds&auml;tzlich die folgenden Zahlungsarten zur Verf&uuml;gung:</p>
    <p><strong>Vorkasse</strong><br/> Bei Auswahl der Zahlungsart Vorkasse nennen wir Ihnen unsere Bankverbindung in
      separater E-Mail und liefern die Ware nach Zahlungseingang.</p>
    <p><strong>PayPal</strong><br/> Im Bestellprozess werden Sie auf die Webseite des Online-Anbieters PayPal
      weitergeleitet. Um den Rechnungsbetrag &uuml;ber PayPal bezahlen zu k&ouml;nnen, m&uuml;ssen Sie dort registriert
      sein bzw. sich erst registrieren, mit Ihren Zugangsdaten legitimieren und die Zahlungsanweisung an uns best&auml;tigen.
      Nach Abgabe der Bestellung im Shop fordern wir PayPal zur Einleitung der Zahlungstransaktion auf. Die
      Zahlungstransaktion wird durch PayPal unmittelbar danach automatisch durchgef&uuml;hrt. Weitere Hinweise erhalten
      Sie beim Bestellvorgang.</p>
    <h2>6.&nbsp;Eigentumsvorbehalt </h2>
    <p>Die Ware bleibt bis zur vollst&auml;ndigen Bezahlung unser Eigentum. F&uuml;r Unternehmer gilt erg&auml;nzend:
      Wir behalten uns das Eigentum an der Ware bis zur vollst&auml;ndigen Begleichung aller Forderungen aus einer
      laufenden Gesch&auml;ftsbeziehung vor. Sie d&uuml;rfen die Vorbehaltsware im ordentlichen Gesch&auml;ftsbetrieb
      weiterver&auml;u&szlig;ern; s&auml;mtliche aus diesem Weiterverkauf entstehenden Forderungen treten Sie &ndash;
      unabh&auml;ngig von einer Verbindung oder Vermischung der Vorbehaltsware mit einer neuen Sache - in H&ouml;he des
      Rechnungsbetrages an uns im Voraus ab, und wir nehmen diese Abtretung an. Sie bleiben zur Einziehung der
      Forderungen erm&auml;chtigt, wir d&uuml;rfen Forderungen jedoch auch selbst einziehen, soweit Sie Ihren
      Zahlungsverpflichtungen nicht nachkommen.</p>
    <h2>7.&nbsp;Transportsch&auml;den </h2>
    <p>Werden Waren mit offensichtlichen Transportsch&auml;den angeliefert, so reklamieren Sie solche Fehler bitte m&ouml;glichst
      sofort beim Zusteller und nehmen Sie bitte unverz&uuml;glich Kontakt zu uns auf. Die Vers&auml;umung einer
      Reklamation oder Kontaktaufnahme hat f&uuml;r Ihre gesetzlichen Anspr&uuml;che und deren Durchsetzung,
      insbesondere Ihre Gew&auml;hrleistungsrechte, keinerlei Konsequenzen. Sie helfen uns aber, unsere eigenen Anspr&uuml;che
      gegen&uuml;ber dem Frachtf&uuml;hrer bzw. der Transportversicherung geltend machen zu k&ouml;nnen.</p>
    <h2>8.&nbsp;Gew&auml;hrleistung und Garantien </h2>
    <p>Es gilt das gesetzliche M&auml;ngelhaftungsrecht. F&uuml;r Verbraucher betr&auml;gt die Verj&auml;hrungsfrist f&uuml;r
      M&auml;ngelanspr&uuml;che bei gebrauchten Sachen ein Jahr ab Ablieferung der Ware.<br/>
      F&uuml;r Unternehmer betr&auml;gt die Verj&auml;hrungsfrist f&uuml;r M&auml;ngelanspr&uuml;che ein Jahr ab Gefahr&uuml;bergang;
      die gesetzlichen Verj&auml;hrungsfristen f&uuml;r den R&uuml;ckgriffsanspruch nach &sect; 478 BGB bleiben unber&uuml;hrt.<br/>
      Gegen&uuml;ber Unternehmern gelten als Vereinbarung &uuml;ber die Beschaffenheit der Ware nur unsere eigenen
      Angaben und die Produktbeschreibungen des Herstellers, die in den Vertrag einbezogen wurden; f&uuml;r &ouml;ffentliche
      &Auml;u&szlig;erungen des Herstellers oder sonstige Werbeaussagen &uuml;bernehmen wir keine Haftung.<br/>
      Ist die gelieferte Sache mangelhaft, leisten wir gegen&uuml;ber Unternehmern zun&auml;chst nach unserer Wahl Gew&auml;hr
      durch Beseitigung des Mangels (Nachbesserung) oder durch Lieferung einer mangelfreien Sache
      (Ersatzlieferung).<br/>
      Die vorstehenden Einschr&auml;nkungen und Fristverk&uuml;rzungen gelten nicht f&uuml;r Anspr&uuml;che aufgrund von
      Sch&auml;den, die durch uns, unsere gesetzlichen Vertreter oder Erf&uuml;llungsgehilfen verursacht wurden<br/>
      &bull; bei Verletzung des Lebens, des K&ouml;rpers oder der Gesundheit<br/>
      &bull; bei vors&auml;tzlicher oder grob fahrl&auml;ssiger Pflichtverletzung sowie Arglist<br/>
      &bull; bei Verletzung wesentlicher Vertragspflichten, deren Erf&uuml;llung die ordnungsgem&auml;&szlig;e Durchf&uuml;hrung
      des Vertrages &uuml;berhaupt erst erm&ouml;glicht und auf deren Einhaltung der Vertragspartner regelm&auml;&szlig;ig
      vertrauen darf (Kardinalpflichten)&#160;<br/>
      &bull; im Rahmen eines Garantieversprechens, soweit vereinbart<br/>
      &bull; soweit der Anwendungsbereich des Produkthaftungsgesetzes er&ouml;ffnet ist.</p>
    <p>Informationen zu gegebenenfalls geltenden zus&auml;tzlichen Garantien und deren genaue Bedingungen finden Sie
      jeweils beim Produkt und auf besonderen Informationsseiten im Online-Shop.</p>
    <h2>9.&nbsp;Haftung </h2>
    <p>F&uuml;r Anspr&uuml;che aufgrund von Sch&auml;den, die durch uns, unsere gesetzlichen Vertreter oder Erf&uuml;llungsgehilfen
      verursacht wurden, haften wir stets unbeschr&auml;nkt<br/>
      &bull; bei Verletzung des Lebens, des K&ouml;rpers oder der Gesundheit,<br/>
      &bull; bei vors&auml;tzlicher oder grob fahrl&auml;ssiger Pflichtverletzung,<br/>
      &bull; bei Garantieversprechen, soweit vereinbart, oder<br/>
      &bull; soweit der Anwendungsbereich des Produkthaftungsgesetzes er&ouml;ffnet ist.<br/>
      Bei Verletzung wesentlicher Vertragspflichten, deren Erf&uuml;llung die ordnungsgem&auml;&szlig;e Durchf&uuml;hrung
      des Vertrages &uuml;berhaupt erst erm&ouml;glicht und auf deren Einhaltung der Vertragspartner regelm&auml;&szlig;ig
      vertrauen darf, (Kardinalpflichten) durch leichte Fahrl&auml;ssigkeit von uns, unseren gesetzlichen Vertretern
      oder Erf&uuml;llungsgehilfen ist die Haftung der H&ouml;he nach auf den bei Vertragsschluss vorhersehbaren Schaden
      begrenzt, mit dessen Entstehung typischerweise gerechnet werden muss. Im &Uuml;brigen sind Anspr&uuml;che auf
      Schadensersatz ausgeschlossen.</p>
    <h2>10.&nbsp;Streitbeilegung </h2>
    <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie <a
        href="https://ec.europa.eu/consumers/odr/" target="_blank">hier</a> finden. Wir sind bereit, an einem au&szlig;ergerichtlichen
      Schlichtungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen. Zust&auml;ndig ist die
      Universalschlichtungsstelle des Bundes am Zentrum f&uuml;r Schlichtung e.V., Stra&szlig;burger Stra&szlig;e 8,
      77694 Kehl am Rhein, <a href="http://www.universalschlichtungsstelle.de" rel="noopener noreferrer"
                              target="_blank">www.universalschlichtungsstelle.de</a>.
    </p>
    <br>
    <p><i><a target="_blank" title="AGB" href="https://shop.trustedshops.com/de/rechtstexte/">AGB</a> erstellt mit dem
      <a target="_blank" title="Trusted Shops" href="https://shop.trustedshops.com/de/">Trusted Shops</a> Rechtstexter
      in Kooperation mit <a target="_blank" title="FÖHLISCH Rechtsanwälte" href="https://foehlisch.com" rel="nofollow">FÖHLISCH
        Rechtsanwälte</a>.</i></p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
@import "./../assets/variables";

.container {
  margin-top: 100px;
  text-align: center;
  color: $grey;

  h2 {
    font-weight: bold;
    font-size: 24px;
  }

  h5 {
    font-size: 18px;
    margin-top: 24px;
    font-weight: normal;

    a {
      color: $grey;
    }
  }
}
</style>

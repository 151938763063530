import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// TODO: fix
// Vue.config.productionTip = false

import FontFaceObserver from 'fontfaceobserver';

import VueSelect from 'vue-select';

const codystar = new FontFaceObserver('Codystar');
const roughDusty = new FontFaceObserver('RoughDusty');
const newWaltograph = new FontFaceObserver('NewWaltograph');
const kalunaScript = new FontFaceObserver('KalunaScript');
const merienda = new FontFaceObserver('Merienda');
const hamburgerHeaven = new FontFaceObserver('HamburgerHeaven');
const emo = new FontFaceObserver('Emo');

codystar.load().then(function () {
    console.log('Codystar is available');
}, function () {
    console.log('Codystar is not available');
});

roughDusty.load().then(function () {
    console.log('RoughDusty is available');
}, function () {
    console.log('RoughDusty is not available');
});

newWaltograph.load().then(function () {
    console.log('New Waltograph is available');
}, function () {
    console.log('New Waltograph is not available');
});

kalunaScript.load().then(function () {
    console.log('kalunaScript is available');
}, function () {
    console.log('kalunaScript is not available');
});

merienda.load().then(function () {
    console.log('merienda is available');
}, function () {
    console.log('merienda is not available');
});

hamburgerHeaven.load().then(function () {
    console.log('hamburgerHeaven is available');
}, function () {
    console.log('hamburgerHeaven is not available');
});

emo.load().then(function () {
    console.log('emo is available');
}, function () {
    console.log('emo is not available');
});


createApp(App)
    .use(router)
    .component("v-select", VueSelect)
    .mount("#app");